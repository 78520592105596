import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import Routes and Route
import './index.css';
import App from './App';
import AnotherComponent from './components/About_us/aboutus'; // Adjust the path as per your project structure
import reportWebVitals from './reportWebVitals';
import { store } from './app/store';
import { Provider } from 'react-redux';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Routes> {/* Use Routes instead of Route */}
          <Route path="/" element={<App />} />
          <Route path="/about-us" element={<AnotherComponent />} /> {/* Use element prop */}
        </Routes>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
