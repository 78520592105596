import React, { Component } from 'react';
import styled from 'styled-components';
import Section from './Section';
import { animateScroll as scroll } from 'react-scroll';
import Header from './Header';
import Footer from './Footer'; // Import Footer component

class Home extends Component {
  scrollToNextSection = () => {
    scroll.scrollTo(window.innerHeight * 2, {
      duration: 500,
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  };

  onLeftButtonClick = () => {
    scroll.scrollTo(window.innerHeight * 2, {
      duration: 500,
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  };

  onOutdoorButtonClick=()=>{
scroll.scrollTo(window.innerHeight,{
  duration: 500,
  delay: 0,
  smooth: 'easeInOutQuart'
})
  }

  render() {
    return (
      
      <Container>

        <Section 
         backgroundImg="images/homepageCh20.jpg"
          leftBtnText="Contact us"
          rightBtnText="Existing Inventory"
          title="Coming Soon"
          description="Please Contact"
          phonenumber="+961 3 999 674"
          onLeftButtonClick={this.onLeftButtonClick}
        />
       
        {/* The rest of your component remains unchanged */}
        <Footer /> {/* Footer component added here */}
      </Container>
    );
  }
}

export default Home;

const Container = styled.div`
  height: 100vh;
  z-index: 10;
`;
