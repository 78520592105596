import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { animateScroll as scroll } from 'react-scroll';
import { Link } from 'react-router-dom'; // Add this import
import { selectCars } from '../features/car/carSlice';
import AboutUs from './About_us/aboutus';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      burgerStatus: false,
    };
  }
  onLeftButtonClick = () => {
    // Calculate the height of one window
    const windowHeight = window.innerHeight;
    // Scroll down by one window height
    scroll.scrollTo(windowHeight, {
      duration: 500,
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  };

  render() {
    const { cars, onLeftButtonClick ,villabutton,onOutdoorButtonClick,indoorButton ,aboutuss} = this.props; // Destructure onLeftButtonClick from props
    const { burgerStatus } = this.state;

    return (
      <Container>
        <a>
          <img src='/images/CH2O_white_outdoors.svg' alt='' style={{ width: '120px', height: '120px' }} />
        </a>
        <Menu>
          <a href='#' onClick={onLeftButtonClick}   >{villabutton} </a> {/* Apply the function */}
        <a href='#' onClick={onOutdoorButtonClick}   >{indoorButton} </a> {/* Apply the function */}
      <Link to="/about-us">
        <a href='#' >{aboutuss}</a>
        </Link>
        </Menu>
       
        <BurgerNav show={burgerStatus}>
          <CloseWrapper>
            <CustomClose onClick={() => this.setState({ burgerStatus: false })} />
          </CloseWrapper>
          {cars &&
            cars.map((car, index) => (
              <li key={index}>
                <a href='#'>{car}</a>
              </li>
            ))}
          <li>
            <a href='#'>Existing Inventory</a>
          </li>
          <li>
            <a href='#'>Used Inventory</a>
          </li>
          <li>
            <a href='#'>Trade-in</a>
          </li>
          <li>
            <a href='#'>Cybertruck</a>
          </li>
          <li>
            <a href='#'>Roadster</a>
          </li>
        </BurgerNav>
      </Container>
    );
  }
}

const CustomMenu = styled(MenuIcon)`
  cursor: pointer;
  /* Add any additional styles you want for the CustomMenu icon here */
`;

const mapStateToProps = (state) => ({
  cars: selectCars(state),
});

export default connect(mapStateToProps)(Header);

const Container = styled.div`
  min-height: 60px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: #; /* Dark blue background */
  opacity: 0.9; // Set the opacity to 0.5 for a semi-transparent effect
  color: white; /* White text color for contrast */
  font-family: 'Montserrat', sans-serif;
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  a {
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 10px;
    flex-wrap: nowrap;
    color: white; /* Ensure menu items are white for visibility */
    font-family: 'Montserrat', sans-serif;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const RightMenu = styled.div`
  display: flex;
  align-items: center;

  a {
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 10px;
    color: white; /* Match the text color with the theme */
  }
`;

const BurgerNav = styled.nav`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background: #003366; /* Dark blue to match the theme */
  width: 300px;
  z-index: 16;
  list-style: none;
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: start;
  transform: ${(props) => (props.show ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.2s;
  color: white; /* Ensure the text is white */

  li {
    padding: 15px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); /* Lighter border for contrast */

    a {
      font-weight: 600;
      color: white; /* White text for readability */
    }
  }
`;

const CustomClose = styled(CloseIcon)`
  cursor: pointer;
  color: white; /* White color for the close icon */
`;

const CloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
