import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer style={styles.footer}>
      <div style={styles.footerContainer}>
        <div style={styles.media} className="social-links">
          {/* Instagram Icon */}
          <a href="https://www.instagram.com/ch2ooutdoors?igsh=cTFuZXJuYnRjazlh" target="_blank"className="social-link">
            <FontAwesomeIcon icon={faInstagram} style={styles.icon} color="blue" />
          </a>

          {/* Facebook Icon */}
          <a href="https://www.facebook.com/ch2olb?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" className="social-link">
  <FontAwesomeIcon icon={faFacebook} style={styles.icon} color="blue" />
</a>


          {/* Add more social links/icons as needed */}
        </div>
      </div>
    </footer>
  );
};

const styles = {
  footer: {
    backgroundColor: '#f2f2f2', // Changed background color to light gray
    opacity:'0.5',
    padding: '20px 0',
    textAlign: 'center',
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
  },
  footerContainer: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '0 20px', // Added padding to adjust for smaller screens
  },
  media: {
    marginTop: '20px',
    display: 'flex', // Added flex display to align icons horizontally
    justifyContent: 'center', // Center icons horizontally
    flexWrap: 'wrap', // Wrap icons to next line if they exceed width
  },
  icon: {
    fontSize: '40px',
   
    margin: '10px',
    borderRadius: '50%', // Make the icon circular
  },
};

export default Footer;
