import React, { Component } from 'react';
import styled from 'styled-components';

class Section extends Component {
    render() {
        const { title, description, leftBtnText, rightBtnText, backgroundImg, onLeftButtonClick, phonenumber } = this.props;

        return (
            <Wrap bgImage={backgroundImg}   >
              
                <ItemText>
                    <h1>{title}</h1>
                    <h2>{description}</h2>
                    <h2>{phonenumber}</h2>
                </ItemText>
           
            </Wrap>
        );
    }
}

export default Section;

const Wrap = styled.div`
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('/images/model-s.jpg');
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: ${props => `url("/images/${props.bgImage}")`}
    
`;

const ItemText = styled.div`
       text-align: center;
    position: absolute;
    top: 10%; /* Adjust this value to move ItemText upwards */
    width: 100%; /* Ensures text alignment works as expected */
    h1 {
        font-family: 'Montserrat', sans-serif;
        color: #FFFFFF; /* Example: white color */
        // background-color: #000000; /* Example: black background */
        // padding: 10px; /* Adds padding around the text for visibility */
        font-size:50px;
    }
    
    h2 {
        font-family: 'Montserrat', sans-serif;
        color: #FFFFFF; /* Example: gold color */
        // background-color: #000000; /* Example: black background */
         padding: 10px; /* Adds padding around the text for visibility */
         
    }
`;

const ButtonGroup = styled.div`
    position: absolute;
    top: 20%; /* Position buttons 20% down from the top */
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LeftButton = styled.div`
    background-color: rgba(23, 26, 32, 0.8);
    height: 40px;
    width: 120px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    opacity: 0.85;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
    margin-right: 10px; /* Add some space between buttons */
`;

const RightButton = styled(LeftButton)`
    background: white;
    opacity: 0.65;
    color: black;
`;

const DownArrow = styled.img`
    height: 40px;
    overflow-x: hidden;
    animation: animateDown infinite 1.5s;
`;
