import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons'; // Correct the import path
import Footer from '../Footer'; // Import Footer component
const AboutUs = () => {
  const styles = `
    /* Style for the about section */
    .about-section {
      padding: 50px 0;
    
    }

    /* Style for the section title */
    .section-title {
      text-align: center;
      color: #333;
      font-size: 2rem;
      margin-bottom: 30px;
      font-family: 'Montserrat', sans-serif; /* Apply Montserrat font */
    }

    /* Style for the container */
    .container {
      width: 80%;
      margin: 0 auto;
    }

    /* Style for the about content */
    .about-content {
      text-align: justify;
      line-height: 1.6;
      color: #666;
      font-family: 'Montserrat', sans-serif; /* Apply Montserrat font */
    }

    /* Style for the paragraphs */
    .about-content p {
      margin-bottom: 20px;
      font-family: 'Montserrat', sans-serif; /* Apply Montserrat font */
      color: black;
      font-weight: bold;
    }

    /* Style for social media links */
    .social-links {
      text-align: center;
    }

    .social-links a {
      color: #333;
      margin: 0 10px;
      font-size: 24px;
    }
  `;

  return (
    <section id="about" className="about-section">
      <style>{styles}</style>
      <div className="container">
        <h2 className="section-title">About Us</h2>
        <div className="about-content">
          <p>
            Welcome to our website! We are a dedicated team passionate about [your mission or purpose]. Our goal is to [briefly describe your mission/goal]. Through our expertise and commitment, we strive to [what you aim to achieve].
          </p>
          <p>
            At [Your Company Name], we believe in [your core values or beliefs]. Our team is comprised of skilled professionals who are committed to delivering [quality/products/services]. We take pride in [specific achievements or milestones].
          </p>
          <p>
            Whether you're [describe your target audience], we are here to [how you serve your audience]. Our website is designed to [describe the purpose of your website].
          </p>
          <p>
            Thank you for visiting us! If you have any questions or would like to [contact or engage with your business], feel free to [call-to-action].
          </p>
        </div>
     
      
      </div>
      <Footer /> {/* Footer component added here */}
    </section>
    
  );
};

export default AboutUs;
